import React from "react"

import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

import ColorPicker from "../colorPicker"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen } from "@fortawesome/free-solid-svg-icons"

import AlbumContext from "../../context/albumContext"
import firebase from "firebase"

import "./trackInfoPopup.scss"

const TrackInfoPopup = ({ track }) => {
  const [textColor, setTextColor] = React.useState(track.meta?.textColor || "#333")
  const [backgroundColor, setBackgroundColor] = React.useState(track.meta?.backgroundColor || "transparent")

  return (
    <form id="TrackInfoForm">
      <div className="field">
        <label className="label">Album</label>
        <div className="control">
          <input className="input" name="album" type="text" defaultValue={track.meta?.album} />
        </div>
      </div>
      <div className="field">
        <label className="label">Artist</label>
        <div className="control">
          <input className="input" name="artist" type="text" defaultValue={track.meta?.artist} />
        </div>
      </div>
      <ColorPicker
        label="Background Color"
        name="backgroundColor"
        hex={backgroundColor}
        onChange={setBackgroundColor}
      />
      <ColorPicker label="Text Color" name="textColor" hex={textColor} onChange={setTextColor} />
      <div className="span m3"></div>
      <div className="label">Is New?</div>
      <div className="field" style={{ marginBottom: 0 }}>
        <input
          type="checkbox"
          id="track-is-new"
          name="isNew"
          className="switch is-link"
          defaultChecked={track.meta?.isNew === true}
        />
        <label htmlFor="track-is-new">&nbsp;</label>
      </div>
    </form>
  )
}

const TrackInfoPopupButton = ({ track }) => {
  const context = React.useContext(AlbumContext)
  const ReactSwal = withReactContent(Swal)

  return (
    <button
      disabled={Object.keys(context.edits).length}
      style={{ fontSize: "0.75rem", border: 0 }}
      className="button is-borderless"
      onClick={() =>
        ReactSwal.fire({
          html: <TrackInfoPopup track={track} />,
          showCancelButton: true,
          confirmButtonText: "Confirm",
          preConfirm: async (value) => {
            ReactSwal.showLoading()
            const form = document.getElementById("TrackInfoForm")
            let item = { ...track, meta: { ...track.meta } }

            for (const input of form.querySelectorAll("input.input")) {
              item.meta[input.getAttribute("name")] = input.value
            }

            for (const checkbox of form.querySelectorAll("input.switch")) {
              item.meta[checkbox.getAttribute("name")] = checkbox.checked
            }

            await firebase
              .firestore()
              .collection("albums")
              .doc(context.id)
              .update({
                [`tracks.${btoa(encodeURIComponent(track.name))}`]: {
                  ...item,
                },
              })
            ReactSwal.hideLoading()
          },
        })
      }>
      <FontAwesomeIcon icon={faPen} className="icon" />
    </button>
  )
}

export default TrackInfoPopupButton
