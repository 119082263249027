import firebase from "firebase"
import { v4 as uuidv4 } from "uuid"

export const generateCode = (length) => {
  var result = ""
  var characters = "ABCDEFGHJKMNPQRSTUVWXYZ23456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const persistCode = async ({ albumId, title, description, code, downloadsAllowed, downloadsUsed }) => {
  if (code) code = code.toUpperCase()

  const query = await firebase.firestore().collection("codes").doc(code).get()
  if (query.exists) {
    throw new Error(`Code '${code}', already exists.`)
  }

  const data = {
    albumId,
    title,
    description,
    datesUsed: [],
    isCustom: true,
    downloadsAllowed,
    downloadsUsed: downloadsUsed || 0,
    createdAt: new Date(),
  }

  await firebase.firestore().collection("codes").doc(code).set(data)
}

export const getAlbumData = async (id) => {
  const album = await firebase.firestore().collection("albums").doc(id).get()
  const pictureUrl = await firebase.storage().ref(album.data().picture).getDownloadURL()

  return { album, pictureUrl }
}

export const uploadAlbumArt = async (albumId, albumData, imageObj) => {
  await firebase.storage().ref(`${albumData.picture}`).delete()
  const imageSnap = await firebase.storage().ref(`${albumId}/${imageObj.name}`).put(imageObj)
  await firebase.firestore().collection("albums").doc(albumId).update({ picture: imageSnap.metadata.fullPath })
}

export const uploadTracks = async (albumId, tracks, order) => {
  for (const [, track] of Object.entries(tracks)) {
    const name = track.name
    const encodedName = btoa(encodeURIComponent(track.name))
    const trackSnap = await firebase.storage().ref(`${albumId}/${track.name}`).put(track)
    const path = trackSnap.metadata.fullPath
    const url = await trackSnap.ref.getDownloadURL()

    await firebase
      .firestore()
      .collection("albums")
      .doc(albumId)
      .update({
        [`tracks.${encodedName}`]: {
          uuid: uuidv4(),
          name,
          path,
          url,
          order,
        },
      })
  }
}

export const uploadFiles = async (albumId, files) => {
  for (const file of files) {
    const name = file.name
    const encodedName = btoa(encodeURIComponent(file.name))
    const trackSnap = await firebase.storage().ref(`${albumId}/${file.name}`).put(file)
    const path = trackSnap.metadata.fullPath
    const url = await trackSnap.ref.getDownloadURL()
    await firebase
      .firestore()
      .collection("albums")
      .doc(albumId)
      .update({
        [`files.${encodedName}`]: {
          uuid: uuidv4(),
          name,
          path,
          url,
        },
      })
  }
}

export const triggerRezip = async (albumId) => {
  const ref = firebase.storage().ref(`${albumId}/.zipme`)

  // Delete object if it exists
  try {
    if (await ref.getDownloadURL()) {
      await ref.delete()
    }
  } catch {}

  await ref.put(new Blob([0x0]))
}

export const handleCodeUpdateSwalValidate = (isGroupForm = false) => {
  const data = {}
  let errorMessage
  const inputs = document.querySelectorAll(".EditorForm input")
  for (const input of inputs) {
    data[input.name] = input.value

    if (input.name !== "code") {
      if (!isNaN(data[input.name])) {
        data[input.name] = parseInt(data[input.name])
      }
    }
  }

  console.log(data, isGroupForm)
  if (!data.title || !data.description || !data.downloadsAllowed) {
    errorMessage = "Please complete the form before continuing."
    throw new Error(errorMessage)
  }

  if (!isGroupForm) {
    if (!data.code && !data.numberOfCodes) {
      errorMessage = "Code must be defined before generating."
      throw new Error(errorMessage)
    }

    const forbiddenChars = "0OIL1"
    if (data.code) {
      for (const char of forbiddenChars.split("")) {
        if (data.code.includes(char)) {
          errorMessage = `Code cannot contain: ${JSON.stringify(forbiddenChars.split(""))}`
          throw new Error(errorMessage)
        }
      }
    }
  }

  return data
}
