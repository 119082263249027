import React from "react"
import firebase from "firebase"
import { triggerRezip } from "../../helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar, faTrash } from "@fortawesome/free-solid-svg-icons"
import AlbumContext from "../../context/albumContext"

const FileItem = ({ file, featured }) => {
  const context = React.useContext(AlbumContext)

  const setFeaturedFile = async (file) => {
    if (featured.file.name !== file.name) {
      await context.albumDoc.ref.update({ ["featured.file"]: file })
      context.setAlbum({ ...context.album, featured: { ...context.album.featured, file } })
    }
  }

  const removeFile = async (file) => {
    context.setLoading(true)
    const docRef = firebase.firestore().collection("albums").doc(context.id)
    const newFiles = { ...context.album.files }
    const key = Object.keys(newFiles).find((k) => newFiles[k] === file)
    delete newFiles[key]

    try {
      // Delete Track
      await firebase.storage().ref(file.path).delete()
      // Trigger rezip
      await triggerRezip(context.id)
    } catch (err) {}

    try {
      // Remove reference
      await docRef.update({ files: { ...newFiles } })
      window.location.reload()
      context.setLoading(false)
    } catch (err) {
      context.setError(err)
      console.error(err)
    }
  }

  return (
    <div className="box file" key={file.name}>
      <div className="media">
        <div className="media-content">
          <div className="content">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <p>
                    <span className="mr-3">
                      {featured.file.name === file.name && <FontAwesomeIcon icon={faStar} className="icon" />}
                      {featured.file.name !== file.name && (
                        <FontAwesomeIcon icon={faStar} className="icon" onClick={setFeaturedFile.bind(this, file)} />
                      )}
                    </span>
                    <span className="track-name">{file.name}</span>
                  </p>
                </div>
              </div>
              <div className="level-right">
                <FontAwesomeIcon icon={faTrash} className="has-text-danger icon" onClick={() => removeFile(file)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileItem
