import React from "react"
import firebase from "firebase/app"
import "firebase/firestore"

import ImportContext from "../context/ImportContext"

import Dropper from "../components/ImportPage/Dropper"
import Form from "../components/ImportPage/Form"
import Progress from "../components/ImportPage/Progress"

class ImportPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      data: null,
      setFile: this.setFile.bind(this),
      setData: this.setData.bind(this),
      triggerReset: this.reset.bind(this),
      triggerImport: this.triggerImport.bind(this),
      isImporting: false,
      hasImported: false,
      status: "",
      errors: [],
    }
  }

  setFile = (file) => this.setState({ ...this.state, file })
  setData = (data) => this.setState({ ...this.state, data })
  reset = () =>
    this.setState({
      ...this.state,
      file: null,
      data: null,
      hasImported: false,
      isImporting: false,
      errors: [],
      status: "",
    })

  triggerImport = async () => {
    this.setState({ ...this.state, isImporting: true, status: "Beginning Import.", errors: [] })

    const albums = {}
    const groups = {}
    const codes = {}

    let cursor = 1
    const total = Object.values(this.state.data).length
    for (const row of Object.values(this.state.data)) {
      // Album must exist that is being imported into
      if (row.albumId) {
        if (!albums[row.albumId]) {
          albums[row.albumId] = await firebase.firestore().collection("albums").doc(row.albumId).get()
        }

        if (!albums[row.albumId].exists) {
          this.setState({
            ...this.state,
            errors: [...this.state.errors, `Unable to process ${row.id}, album '${row.albumId}' does not exist.`],
          })
          cursor++
          continue
        }
      }

      // Create group if not exists
      if (row.generationId && row.generationId !== "n/a") {
        if (!codes[row.generationId]) codes[row.generationId] = []

        if (!groups[row.generationId])
          groups[row.generationId] = await firebase.firestore().collection("groups").doc(row.generationId).get()

        if (!groups[row.generationId].exists) {
          await groups[row.generationId].ref.set({
            title: row.title,
            description: row.description,
            albumId: row.albumId,
            codes: [],
            downloadsAllowed: parseInt(row.downloadsAllowed),
            createdAt: new Date(),
          })
          groups[row.generationId] = await groups[row.generationId].ref.get()
        }
      }

      console.log(row)

      // Upsert
      await firebase
        .firestore()
        .collection("codes")
        .doc(row.id)
        .set(
          {
            albumId: row.albumId,
            title: row.title,
            generationId: row.generationId !== "n/a" ? row.generationId : null,
            description: row.description,
            downloadsUsed: parseInt(row.downloadsUsed),
            downloadsAllowed: parseInt(row.downloadsAllowed),
            datesUsed: JSON.parse(row.datesUsed),
            createdAt: new Date(row.createdAt),
            importedAt: new Date(),
          },
          { merge: true }
        )

      if (row.generationId !== "n/a") codes[row.generationId].push(row.id)

      this.setState({ ...this.state, status: `Processed: ${cursor++} / ${total}` })
    }

    // Update refs in group obj
    for (const group of Object.keys(codes)) {
      console.log(group, groups[group].data())
      const toBeAdded = [...new Set([...groups[group].data().codes, ...codes[group]])]
      await groups[group].ref.update({ codes: toBeAdded })
      console.log(toBeAdded)
    }

    this.setState({ ...this.state, isImporting: false, hasImported: true, file: null, data: null })
  }

  render() {
    return (
      <ImportContext.Provider value={this.state}>
        <div className="ImportPage">
          <section className="section">
            {!this.state.data && !this.state.hasImported && <Dropper />}
            {this.state.data && !this.state.isImporting && <Form />}
            {this.state.data && this.state.isImporting && <Progress />}
            {!this.state.data && this.state.hasImported && (
              <div className="container">
                <div className="message">
                  <div className="message-body">
                    <div className="level">
                      <div className="level-item level-left">
                        <p>Import completed.</p>
                      </div>
                      <div className="level-item level-right">
                        <button className="button is-primary" onClick={this.reset}>
                          Start Over?
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="container">
              {this.state.errors && (
                <div className="messages my-3">
                  {this.state.errors.map((err) => (
                    <div className="message is-danger" key={btoa(err)}>
                      <div className="message-body">{err}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
        </div>
      </ImportContext.Provider>
    )
  }
}

export default ImportPage
