import FileItem from "./fileItem"

const FileList = ({ files, featured }) => {
  if (!files)
    return (
      <p>
        <em>No files found.</em>
      </p>
    )
  return Object.values(files)
    .sort((a, b) => (a.name >= b.name ? 1 : -1))
    .map((file) => <FileItem key={file.name} file={file} featured={featured} />)
}

export default FileList
