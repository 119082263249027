import React from "react"
import firebase from "firebase/app"
import "firebase/firestore"
import { v4 as uuidv4 } from "uuid"

class HelpersPage extends React.Component {
  cleanupDangling = async () => {
    const groupDocs = await firebase.firestore().collection("groups").get()
    const groupIds = groupDocs.docs.map((g) => g.id)

    const query = await firebase.firestore().collection("codes").where("albumId", "==", "LmziRhjJoDlPt8mMTRze").get()
    for (const doc of query.docs) {
      const generationId = doc.data().generationId
      if (!groupIds.includes(generationId)) {
        console.log(`Group for generation: ${generationId} not found. Deleting`)
        await doc.ref.delete()
      } else {
        console.log(`Found group for ${generationId}`)
      }
    }
  }

  addGuidToTracks = async () => {
    const albums = await firebase.firestore().collection("albums").get()

    for (const album of albums.docs) {
      const { tracks } = album.data()

      for (const [id] of Object.entries(tracks)) {
        tracks[id].uuid = uuidv4()
      }
      await album.ref.update({ tracks })
    }
  }

  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="panel">
            <div className="panel-heading">Helpers</div>
            <div className="panel-block">
              <div className="container">
                <button className="button is-danger" onClick={this.cleanupDangling}>
                  Cleanup Dangling Codes
                </button>
                <button className="button is-danger" onClick={this.addGuidToTracks}>
                  Add GUID to Tracks
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HelpersPage
