import React from "react"
import TrackItem from "./trackItem"
import AlbumContext from "../../context/albumContext"
import { arrayMoveMutable } from "array-move"

const TrackList = ({ tracks, hideDisabled }) => {
  const context = React.useContext(AlbumContext)

  if (!tracks) {
    return (
      <p>
        <em>No files found.</em>
      </p>
    )
  }

  /**
   * This is called when a track is drag moved.
   * @param {number} dragIndex
   * @param {number} hoverIndex
   */
  const moveTrack = (dragIndex, hoverIndex) => {
    // Sort tracks according to their order (DESC)
    const temp = Object.values(tracks).sort((a, b) => a.order - b.order)

    // Move the dragged track index to the hovered index.
    arrayMoveMutable(temp, dragIndex, hoverIndex)

    // Now we have to rebuild the track object using the new order (yes, dumb, I know.)
    const newTracks = { ...tracks }
    const entries = Object.entries(newTracks)

    for (const [index, track] of Object.entries(temp)) {
      const [key] = entries.find(([k, t]) => t.name === track.name)
      newTracks[key].order = parseInt(index)
    }

    context.setEditedTrackOrder(newTracks)
  }

  return Object.values(tracks)
    .sort((a, b) => (a.disabled || false) - (b.disabled || false))
    .sort((a, b) => (a.order + 1 || Infinity) - (b.order + 1 || Infinity))
    .map((track, index) => {
      if (hideDisabled && track.disabled) {
        return null
      } else {
        return (
          <TrackItem
            key={`track-item-${track.uuid})}`}
            track={track}
            index={track.order || index}
            moveTrack={moveTrack}
          />
        )
      }
    })
}

export default TrackList
