import React from "react"
import { withRouter } from "react-router-dom"
import firebase from "firebase/app"
import "firebase/auth"
import Swal from "sweetalert2"

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      isSubmitting: false,
      hasError: false,
      errorDescription: null,
    }
  }

  submit = async () => {
    if (!this.state.email || !this.state.password) {
      this.setState({
        ...this.state,
        isSubmitting: false,
        hasError: true,
        errorMessage: "Must supply username and password",
      })
      return
    }

    try {
      this.setState({ ...this.state, isSubmitting: true })
      await firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      this.props.history.push("/")
      this.setState({ ...this.state, isSubmitting: false })
    } catch (err) {
      this.setState({ ...this.state, isSubmitting: false, hasError: true, errorDescription: err.message })
    }
  }

  register = async () => {
    if (!this.state.email || !this.state.password) {
      this.setState({
        ...this.state,
        isSubmitting: false,
        hasError: true,
        errorMessage: "Must supply username and password",
      })
      return
    }

    try {
      this.setState({ ...this.state, isSubmitting: true })
      await firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
      this.props.history.push("/")
      this.setState({ ...this.state, isSubmitting: false })
    } catch (err) {
      this.setState({ ...this.state, isSubmitting: false, hasError: true, errorDescription: err.message })
    }
  }

  forgotPassword = () => {
    Swal.fire({
      title: "Forgot Password?",
      input: "email",
      text: "An email will be sent to you with a password reset.",
      inputAttributes: {
        placeholder: "bob@example.com",
      },
      showCancelButton: true,
      preConfirm: async (val) => {
        try {
          Swal.showLoading()
          await firebase.auth().sendPasswordResetEmail(val)
          Swal.fire({
            title: "Please check your email",
            toast: true,
            timer: 2000,
            icon: "success",
            showConfirmButton: false,
            position: "top-end",
          })
        } catch (err) {
          Swal.showValidationMessage(err)
        }
      },
    })
  }

  render() {
    return (
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-4 is-offset-4">
              <div className="panel">
                <div className="panel-heading">
                  <h1>Login</h1>
                </div>
                <div className="panel-block">
                  <div className="container">
                    <div className="field">
                      <input
                        type="email"
                        className="input"
                        required={true}
                        placeholder="Email"
                        onKeyUp={(e) => this.setState({ ...this.state, email: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className="panel-block">
                  <div className="container">
                    <div className="field">
                      <input
                        type="password"
                        className="input"
                        required={true}
                        placeholder="Password"
                        onKeyUp={(e) => this.setState({ ...this.state, password: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className="panel-block">
                  <div className="container">
                    <div className="columns">
                      <div className="column">
                        <button className="button is-link is-block is-fullwidth" onClick={this.submit}>
                          Login
                        </button>
                      </div>
                      <div className="column">
                        <button className="button is-link is-block is-outlined is-fullwidth" onClick={this.register}>
                          Register
                        </button>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <button
                          className="button is-link is-block is-outlined is-fullwidth"
                          onClick={this.forgotPassword}>
                          Forgot Password?
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.hasError && this.state.errorDescription && (
                <div className="message is-danger">
                  <div className="message-header">Error</div>
                  <div className="message-body">{this.state.errorDescription}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(LoginPage)
