import React from "react"
import CodeGeneratorForm from "../codeGeneratorForm"

import firebase from "firebase"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

import { persistCode, generateCode, handleCodeUpdateSwalValidate } from "../../helpers"
import AlbumContext from "../../context/albumContext"

const CODE_LENGTH = 8

const GenerateCodeFormButtom = () => {
  const context = React.useContext(AlbumContext)
  const ReactSwal = withReactContent(Swal)

  // Parses form into object from sweetalert2
  const onCodeGeneratorPreConfirm = async () => {
    try {
      const newData = handleCodeUpdateSwalValidate()
      ReactSwal.showLoading()
      ReactSwal.disableButtons()
      await generate(newData)
      ReactSwal.hideLoading()
    } catch (err) {
      ReactSwal.showValidationMessage(err)
    }
  }

  // Select the generate command to used based on whether multiple codes have been requested
  const generate = async (values) => {
    try {
      if (values.numberOfCodes) {
        await generateCodes(values)
      } else {
        await persistCode({ ...values, albumId: context.id })
      }
    } catch (err) {
      ReactSwal.showValidationMessage(err)
    }
  }

  // Generate multiple codes
  const generateCodes = async ({ title, description, numberOfCodes, downloadsAllowed }) => {
    try {
      const increment = 500
      const generationId = firebase.firestore().collection("codes").doc().id
      const groupRef = firebase.firestore().collection("groups").doc(generationId)
      const codes = []

      // Set group for relationship
      await groupRef.set({
        albumId: context.id,
        title,
        description,
        numberOfCodes,
        downloadsAllowed,
        codes,
        createdAt: new Date(),
      })

      // Batch create codes
      for (let batchIndex = 0; batchIndex < numberOfCodes; batchIndex += increment) {
        const batch = firebase.firestore().batch()
        const limit = batchIndex + increment < numberOfCodes ? batchIndex + increment : numberOfCodes

        for (let codeIndex = batchIndex; codeIndex < limit; codeIndex++) {
          const code = generateCode(CODE_LENGTH)
          const data = {
            albumId: context.id,
            generationId,
            title,
            description,
            isCustom: false,
            datesUsed: [],
            downloadsAllowed,
            downloadsUsed: 0,
            createdAt: new Date(),
          }
          const ref = firebase.firestore().collection("codes").doc(code)
          batch.set(ref, data)
          codes.push(code)
        }

        await batch.commit()
      }

      // Add a reference in the group to the codes it owns
      await groupRef.update({ codes })
    } catch (err) {
      context.setError(err)
      console.error(err)
    }
  }

  return (
    <button
      className="button is-link"
      onClick={() =>
        ReactSwal.fire({
          html: <CodeGeneratorForm />,
          showCancelButton: true,
          confirmButtonText: "Create",
          preConfirm: onCodeGeneratorPreConfirm,
        })
      }>
      Generate
    </button>
  )
}

export default GenerateCodeFormButtom
