import React from "react"
import AlbumContext from "../../context/albumContext"
import Loader from "../loader"
import GenerateReportButton from "./generateReportButton"
import SaveButton from "./saveButton"
import ImageUploader from "react-images-upload"

const EditorPanel = () => {
  const context = React.useContext(AlbumContext)

  const onImageDrop = ([picture]) => context.setEdits({ ...context.edits, picture })

  return (
    <div className="panel">
      <div className="panel-heading">
        <div className="level">
          <p>Editing {context.album ? context.album.name : ""}</p>
          {context.albumDoc && context.albumDoc.data().isProcessing && (
            <div className="processing-loader">
              <Loader size={"small"} description={"Processing"} />
            </div>
          )}
          <GenerateReportButton id={context.id} />
          <SaveButton />
        </div>
      </div>
      <div className="panel-block">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="label">Album Name</div>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Cool Album Name"
                    defaultValue={context.album.name}
                    onKeyUp={(e) =>
                      context.setEdits({
                        ...context.edits,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="label">Album Description / Subtitle</div>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    defaultValue={context.album.description}
                    placeholder="You know. A description or subtitle"
                    onKeyUp={(e) => context.setEdits({ ...context.edits, description: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="label">Album Artist</div>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Shawn Williams"
                    defaultValue={context.album.artist}
                    onKeyUp={(e) => context.setEdits({ ...context.edits, artist: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="label">Album Art</div>
                <ImageUploader
                  withIcon={true}
                  withPreview={true}
                  buttonText="Choose images"
                  onChange={onImageDrop}
                  imgExtension={[".jpg", ".png", ".gif"]}
                  maxFileSize={5242880}
                  singleImage={true}
                />
                {!context.edits.picture && (
                  <div className="has-text-centered">
                    <p>Current Image</p>
                    <figure className="image is-256x256 album-art">
                      <img src={context.pictureUrl} alt="album-art" />
                    </figure>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditorPanel
