import React from "react"
import AlbumContext from "../../context/albumContext"

import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons"
import ColorPicker from "../colorPicker"

const SettingsPanel = () => {
  const ReactSwal = withReactContent(Swal)

  const context = React.useContext(AlbumContext)

  const handleEmbedPopup = () => {
    const code1 = `<div class="vigil-project-embed" data-album-id="${context.id}"></div>
<script src="https://admin.vigilproject.app/helper.js"></script>`
    const downloadLink = `https://us-central1-the-vigil-project.cloudfunctions.net/download?albumId=${context.id}`
    ReactSwal.fire({
      title: "Embed Code",
      html: (
        <div>
          <p>
            <strong>This is a generated code specific to THIS ALBUM.</strong>
          </p>
          <pre>{code1}</pre>
          <p>Direct download link for this album:</p>
          <pre>{downloadLink}</pre>
        </div>
      ),
    })
  }

  return (
    <div className="panel">
      <div className="panel-heading">
        <div className="level">
          <div className="level-item level-left">Settings</div>
          {context.album.requiresCode === false && (
            <div className="level-item level-right">
              <FontAwesomeIcon icon={faExternalLinkSquareAlt} className="icon" onClick={handleEmbedPopup} />
            </div>
          )}
        </div>
      </div>
      <div className="panel-body">
        <div className="panel-block">
          <div className="container">
            <ColorPicker
              label="Text Color"
              hex={context.album.textColor || "#333"}
              onChange={(textColor) => context.setEdits({ ...context.edits, textColor })}
            />
            <ColorPicker
              label="Accent Color"
              hex={context.album.accentColor || "#523b6d"}
              onChange={(accentColor) => context.setEdits({ ...context.edits, accentColor })}
            />
            <ColorPicker
              label="Accent Text Color"
              hex={context.album.accentTextColor || "#fff"}
              onChange={(accentTextColor) => context.setEdits({ ...context.edits, accentTextColor })}
            />
            <div className="label">Show Redeem Button?</div>
            <div className="field">
              <input
                type="checkbox"
                id="show-redeem-button-switch"
                name="show-redeem-button-switch"
                className="switch is-link"
                defaultChecked={context.album.showRedeemButton !== undefined ? context.album.showRedeemButton : true}
                onChange={(e) => context.setEdits({ ...context.edits, showRedeemButton: e.target.checked })}
              />
              <label htmlFor="show-redeem-button-switch">&nbsp;</label>
            </div>
            {(context.edits.showRedeemButton === true || context.album.showRedeemButton !== false) && (
              <div className="field">
                <div className="label">Redeem Button Text</div>
                <input
                  type="text"
                  className="input"
                  defaultValue={context.album.redeemButtonText || "Download Your Bundle"}
                  onKeyUp={(e) => context.setEdits({ ...context.edits, redeemButtonText: e.target.value })}
                />
              </div>
            )}
            <div className="label">Show Attachment Button?</div>
            <div className="field">
              <input
                type="checkbox"
                id="show-attachment-button-switch"
                name="show-attachment-button-switch"
                className="switch is-link"
                defaultChecked={
                  context.album.showAttachmentButton !== undefined ? context.album.showAttachmentButton : true
                }
                onChange={(e) => context.setEdits({ ...context.edits, showAttachmentButton: e.target.checked })}
              />
              <label htmlFor="show-attachment-button-switch">&nbsp;</label>
            </div>
            {(context.edits.showAttachmentButton === true || context.album.showAttachmentButton !== false) && (
              <div className="field">
                <div className="label">Attachment Button Text</div>
                <input
                  type="text"
                  className="input"
                  defaultValue={context.album.attachmentButtonText || "Download Attachment"}
                  onKeyUp={(e) => context.setEdits({ ...context.edits, attachmentButtonText: e.target.value })}
                />
              </div>
            )}
            <div className="label">Requires Code?</div>
            <div className="field">
              <input
                type="checkbox"
                id="requires-code-switch"
                name="requires-code-switch"
                className="switch is-link"
                defaultChecked={context.album.requiresCode !== undefined ? context.album.requiresCode : true}
                onChange={(e) => context.setEdits({ ...context.edits, requiresCode: e.target.checked })}
              />
              <label htmlFor="requires-code-switch">&nbsp;</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsPanel
