import { createContext } from "react"

export const DEFAULT_STATE = {
  id: null,
  album: null,
  albumDoc: null,
  codes: {},
  groups: {},
  edits: {},
  pictureUrl: null,
  trackUrls: {},
  tracks: [],
  files: [],
  isLoading: true,
  hasError: false,
  errorDescription: null,
  loadingDescription: null,
}

const AlbumContext = createContext({ ...DEFAULT_STATE })

export default AlbumContext
