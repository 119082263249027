import React from "react"
import ImageUploader from "react-images-upload"
import Dropzone from "react-dropzone"
import { withRouter } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

import Loader from "../components/loader"

import firebase from "firebase"
import "firebase/firestore"
import "firebase/storage"

import "./createAlbum.scss"

class CreateAlbumPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: null,
      artist: null,
      description: null,
      picture: null,
      tracks: [],
      files: [],
      isLoading: false,
      hasError: false,
      errorDescription: null,
      loadingDescription: null,
    }
  }

  shouldDisableCreateButton = () => !this.state.picture || !this.state.tracks.length || !this.state.name

  onImageDrop = ([picture]) => {
    this.setState({ ...this.state, picture })
  }

  onFileDrop = (type, files) => this.setState({ ...this.state, [type]: [...this.state[type], ...files] })

  create = async () => {
    this.setState({ ...this.state, isLoading: true })
    try {
      const collectionRef = firebase.firestore().collection("albums")
      const data = {
        name: this.state.name,
        artist: this.state.artist,
        description: this.state.description,
        picture: "",
        accentColor: "#523b6d",
        backgroundColor: "transparent",
        textColor: "#333",
        accentTextColor: "#fff",
        showRedeemButton: true,
        showAttachmentButton: true,
        redeemButtonText: "Download Your Bundle",
        attachmentButtonText: "Download Attachment",
        requiresCode: true,
        tracks: {},
        files: {},
        featured: {
          file: {},
        },
      }
      const createdDoc = await collectionRef.add(data)
      this.setState({ ...this.state, loadingDescription: "Uploading album art" })
      const imageSnap = await firebase
        .storage()
        .ref(`${createdDoc.id}/${this.state.picture.name}`)
        .put(this.state.picture)
      await createdDoc.update({ picture: imageSnap.metadata.fullPath })

      for (const [order, track] of Object.entries(this.state.tracks)) {
        this.setState({ ...this.state, loadingDescription: `Uploading track ${order}` })
        const name = track.name
        const trackSnap = await firebase.storage().ref(`${createdDoc.id}/${track.name}`).put(track)
        const path = trackSnap.metadata.fullPath
        const url = await trackSnap.ref.getDownloadURL()
        await createdDoc.update({
          [`tracks.${btoa(track.name)}`]: {
            name,
            path,
            url,
            order: parseInt(order),
          },
        })
      }

      // Initiate the zipper trigger
      await firebase
        .storage()
        .ref(`${createdDoc.id}/.zipme`)
        .put(new Blob([0x0]))

      this.setState({ isLoading: false, loadingDescription: null })
      this.props.history.push("/")
    } catch (err) {
      this.setState({ isLoading: false, hasError: true, errorDescription: err })
      console.error(err)
    }
  }

  renderFiles = (type) => {
    const results = []
    let index = 1
    for (const file of this.state[type]) {
      results.push(
        <div className="box" key={file.name}>
          <div className="media">
            <div className="media-content">
              <div className="content">
                <div className="level">
                  <div className="level-left">
                    <div className="level-item">
                      <p>
                        <span className="track-index">{index++}. </span>
                        <span className="track-name">{file.name}</span>
                      </p>
                    </div>
                  </div>
                  <div className="level-right">
                    <div className="level-item">
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="is-text-danger"
                        onClick={() =>
                          this.setState({ ...this.state, [type]: this.state[type].filter((t) => t !== file) })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return results
  }

  render() {
    return (
      <div className="CreateAlbumPage">
        <section className="section">
          <div className="container">
            {this.state.hasError && this.state.errorDescription && (
              <div className="message is-danger">
                <div className="message-header">Error</div>
                <div className="message-body">{this.state.errorMessage}</div>
              </div>
            )}
            <div className="panel is-link">
              <p className="panel-heading">Create Album</p>
              <div className="panel-block">
                {this.state.isLoading && !this.state.hasError && (
                  <div className="container">
                    <Loader description={this.state.loadingDescription} />
                  </div>
                )}
                {!this.state.isLoading && (
                  <div className="container">
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="label">Album Name</div>
                          <div className="control">
                            <input
                              type="text"
                              className="input"
                              placeholder="Cool Album Name"
                              onKeyUp={(e) => this.setState({ ...this.state, name: e.target.value })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="label">Album Description / Subtitle</div>
                          <div className="control">
                            <input
                              type="text"
                              className="input"
                              placeholder="You know. A description or subtitle"
                              onKeyUp={(e) => this.setState({ ...this.state, description: e.target.value })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="label">Album Artist</div>
                          <div className="control">
                            <input
                              type="text"
                              className="input"
                              placeholder="Shawn Williams"
                              onKeyUp={(e) => this.setState({ ...this.state, artist: e.target.value })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="label">Album Art</div>
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            buttonText="Choose images"
                            onChange={this.onImageDrop}
                            imgExtension={[".jpg", ".png", ".gif"]}
                            maxFileSize={5242880}
                            singleImage={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="label">Tracks</div>
                          {this.renderFiles("tracks")}
                          <Dropzone
                            onDrop={(files) => this.onFileDrop("tracks", files)}
                            accept={[".mp3", ".aac", ".m4a"]}>
                            {({ getRootProps, getInputProps }) => (
                              <section className="dropzone">
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <p>Drag and drop files here, or click to select files.</p>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="label">Files</div>
                          {this.renderFiles("files")}
                          <Dropzone onDrop={(files) => this.onFileDrop("files", files)}>
                            {({ getRootProps, getInputProps }) => (
                              <section className="dropzone">
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <p>Drag and drop files here, or click to select files.</p>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <button
                          className="button is-block is-fullwidth is-link"
                          disabled={this.shouldDisableCreateButton()}
                          onClick={this.create}>
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(CreateAlbumPage)
