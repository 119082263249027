import React from "react"
import ImportContext from "../../context/ImportContext"

import "./Form.scss"

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  getGroupCount = () => {
    const groups = {}
    for (const { generationId } of this.context.data) {
      if (generationId && generationId !== "n/a" && !groups[generationId]) groups[generationId] = true
    }

    return Object.keys(groups).length
  }

  renderCSV = () => {
    const results = { header: [], body: [] }

    for (let row = 0; row < this.context.data.length; row++) {
      const values = Object.values(this.context.data[row])
      if (row === 0) {
        results.header.push(Object.keys(this.context.data[row]).map((r) => <th key={r}>{r}</th>))
      }
      results.body.push(
        <tr key={`csv-row-${row}`}>
          {values.map((r, i) => (
            <td key={`csv-${row}-${i}`}>{r}</td>
          ))}
        </tr>
      )
    }

    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>{results.header}</tr>
          </thead>
          <tbody>{results.body}</tbody>
        </table>
      </div>
    )
  }

  render() {
    return (
      <div className="ImportPage-Form">
        <div className="container">
          <section className="section">
            <div className="panel">
              <div className="panel-heading">Viewing Import Data</div>
              <div className="panel-block">
                <div className="container">
                  {this.context.data.length && (
                    <>
                      <div className="info my-3">
                        <p>
                          <strong>{this.context.data.length}</strong> records found.
                        </p>
                        <p>
                          <strong>{this.getGroupCount()}</strong> group(s) found.
                        </p>
                      </div>
                      <div className="my-3">{this.renderCSV()}</div>
                    </>
                  )}
                </div>
              </div>
              <div className="panel-footer">
                <div className="container">
                  <div className="p-2">
                    <div className="level">
                      <div className="level-item level-left">
                        <button className="button" onClick={this.context.triggerReset}>
                          Cancel
                        </button>
                      </div>
                      <div className="level-item level-right">
                        <button className="button is-danger" onClick={this.context.triggerImport}>
                          Import
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

Form.contextType = ImportContext
export default Form
