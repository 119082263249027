import React from "react"
import { SketchPicker } from "react-color"
import "./colorPicker.scss"

class ColorPicker extends React.Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
    this.inputRef = React.createRef()
    this.debounce = null
    this.state = {
      name: props.name || "color",
      label: props.label,
      background: props.hex || "#fff",
      isFocused: false,
      isLight: props.hex ? this.lightOrDark(props.hex) === "light" : true,
    }
  }

  handleChangeComplete = (color) => {
    this.setState({ ...this.state, background: color.hex, isLight: this.lightOrDark(color.hex) === "light" })
    this.inputRef.current.value = this.state.background

    if (typeof this.props.onChange === "function") {
      this.props.onChange.apply(this, [this.state.background])
    }
  }

  checkIfFocused = () => {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      console.log(this.containerRef.current)
      window.cref = this.containerRef.current
      const isFocused = this.containerRef.current.contains(document.activeElement)
      this.setState({ ...this.state, isFocused })
    }, 100)
  }

  lightOrDark = (color) => {
    // Variables for red, green, blue values
    var r, g, b, hsp

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

      r = color[1]
      g = color[2]
      b = color[3]
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"))

      r = color >> 16
      g = (color >> 8) & 255
      b = color & 255
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return "light"
    } else {
      return "dark"
    }
  }

  render() {
    return (
      <div ref={this.containerRef} className="ColorPicker" onFocus={this.checkIfFocused} onBlur={this.checkIfFocused}>
        <div className="field">
          <div className="label">{this.state.label}</div>
          <input
            name={this.props.name}
            ref={this.inputRef}
            type="text"
            className={`input ${this.state.isLight ? "is-light" : "is-dark"}`}
            defaultValue={this.state.background}
            minLength={1}
            maxLength={7}
            onChange={(e) => this.handleChangeComplete(e.target.value)}
            style={{ backgroundColor: this.state.background }}
          />
        </div>
        <SketchPicker
          className={`color-picker-popup ${this.state.isFocused ? "is-visible" : ""}`}
          color={this.state.background}
          onChangeComplete={this.handleChangeComplete}
        />
      </div>
    )
  }
}

export default ColorPicker
