import React from "react"
import ImportContext from "../../context/ImportContext"
import Dropzone from "react-dropzone"
import Papa from "papaparse"

class Dropper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  onFileDrop = (files) => {
    this.setState({ ...this.state, isLoading: true })
    this.context.setFile(files[0])

    Papa.parse(files[0], {
      header: true,
      complete: (results) => {
        this.context.setData(results.data)
      },
    })
  }

  render() {
    return (
      <div className="container">
        <section className="section">
          <div className="panel">
            <div className="panel-heading">Import</div>
            <div className="panel-block">
              <div className="container">
                <div className="my-3">
                  <p>This page handles imports from a previously exported TSV report.</p>
                </div>
                <Dropzone onDrop={this.onFileDrop} maxFiles={1} accept={[".tsv", ".tab"]}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag and drop a file here, or click to select a file.</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

Dropper.contextType = ImportContext
export default Dropper
