import React from "react"
import AlbumContext from "../../context/albumContext"

const SaveButton = () => {
  const context = React.useContext(AlbumContext)
  return (
    <button className="button is-link" disabled={!Object.keys(context.edits).length} onClick={context.save}>
      Save
    </button>
  )
}

export default SaveButton
