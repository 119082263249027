import React from "react"
import { withRouter } from "react-router-dom"

import firebase from "firebase"
import "firebase/firestore"
import "firebase/storage"

import Swal from "sweetalert2"

import { getAlbumData, uploadAlbumArt, uploadTracks, uploadFiles, triggerRezip } from "../helpers"

import Loader from "../components/loader"
import CodeList from "../components/codeList"
import GroupList from "../components/groupList"

import "./viewAlbum.scss"
import TrackList from "../components/album/trackList"
import FileList from "../components/album/fileList"
import AlbumContext from "../context/albumContext"
import SettingsPanel from "../components/album/settingsPanel"
import EditorPanel from "../components/album/editorPanel"
import TrackDropzone from "../components/album/trackDropzone"
import TrackDropzoneList from "../components/album/trackDropzoneList"
import FileDropzone from "../components/album/fileDropzone"
import FileDropzoneList from "../components/album/fileDropzoneList"
import GenerateCodeFormButtom from "../components/album/generateCodeFormButton"
import SaveButton from "../components/album/saveButton"

class ViewAlbumPage extends React.Component {
  constructor(props) {
    super(props)
    this.albumListener = null
    this.codeListener = null
    this.groupListener = null
    this.state = {
      id: this.props.match.params.id,
      album: null,
      albumDoc: null,
      codes: {},
      groups: {},
      edits: {},
      trackUrls: {},
      files: [],
      tracks: [],
      pictureUrl: null,
      isLoading: true,
      hasError: false,
      errorDescription: null,
      loadingDescription: null,
      setLoading: this.setLoading,
      setError: this.setError,
      setAlbum: this.setAlbum,
      setEdits: this.setEdits,
      setEditedTrackOrder: this.setEditedTrackOrder,
      save: this.save,
      hideDisabled: true,
    }
  }

  componentDidMount() {
    this.getAlbum()
    this.registerListeners()
  }

  componentWillUnmount() {
    this.deregisterListeners()
  }

  registerListeners = () => {
    // Album
    this.albumListener = firebase
      .firestore()
      .collection("albums")
      .doc(this.state.id)
      .onSnapshot({ includeMetadataChanges: true }, (snap) =>
        this.setState({ ...this.state, albumDoc: snap, album: snap.data() })
      )

    // Individual codes (not tied to a group)
    this.codeListener = firebase
      .firestore()
      .collection("codes")
      .where("albumId", "==", this.state.id)
      .where("isCustom", "==", true)
      .onSnapshot({ includeMetadataChanges: true }, (snap) => this.handleSnapshot(snap, "codes"))

    // Groups
    this.groupListener = firebase
      .firestore()
      .collection("groups")
      .where("albumId", "==", this.state.id)
      .onSnapshot({ includeMetadataChanges: true }, (snap) => this.handleSnapshot(snap, "groups"))
  }

  deregisterListeners = () => {
    this.albumListener()
    this.codeListener()
    this.groupListener()
  }

  handleSnapshot = (snap, key) => {
    let newState
    for (const change of snap.docChanges()) {
      switch (change.type) {
        case "added":
        case "modified":
          newState = { ...this.state }
          newState[key][change.doc.id] = change.doc
          this.setState(newState)
          break
        default:
          // 'removed'
          newState = { ...this.state }
          delete newState[key][change.doc.id]
          this.setState(newState)
          break
      }
    }
  }

  setLoading = (isLoading, loadingDescription) =>
    this.setState({ ...this.state, isLoading, loadingDescription, hasError: false })

  setError = (errorDescription) => this.setState({ ...this.state, hasError: true, isLoading: false, errorDescription })

  setAlbum = (album) => this.setState({ ...this.state, album })

  setEdits = (edits) => this.setState({ ...this.state, edits })

  setEditedTrackOrder = (tracks) => this.setState({ ...this.state, edits: { ...this.state.edits, trackOrder: tracks } })

  getAlbum = async () => {
    this.setLoading(true)
    try {
      const { album, pictureUrl } = await getAlbumData(this.state.id)
      this.setState({ ...this.state, isLoading: false, album: album.data(), pictureUrl })
    } catch (err) {
      this.setError(err)
    }
  }

  save = async () => {
    if (this.state.albumDoc.data().isProcessing) {
      return Swal.fire({
        title:
          "Cannot save changes while album is processing.\nProcessing can take upwards of 10 minutes.\nTry again later.",
        icon: "error",
        toast: true,
        position: "top-right",
      })
    }

    this.setLoading(true)

    const docRef = firebase.firestore().collection("albums").doc(this.state.id)

    // This is a stupid document, I realize that.
    // Future Joseph, you'll probably roll your eyes looking at this.
    // But you need to coerce edits and default values/existing values.
    const data = {
      name: this.state.edits.name || this.state.album.name,
      artist: this.state.edits.artist || this.state.album.artist,
      description: this.state.edits.description || this.state.album.description,
      requiresCode:
        this.state.edits.requiresCode !== undefined ? this.state.edits.requiresCode : this.state.album.requiresCode,
      accentColor: this.state.edits.accentColor || this.state.album.accentColor || "#523b6d",
      backgroundColor: this.state.edits.backgroundColor || this.state.album.backgroundColor || "transparent",
      textColor: this.state.edits.textColor || this.state.album.textColor || "#333",
      accentTextColor: this.state.edits.accentTextColor || this.state.album.accentTextColor || "#fff",
      showRedeemButton:
        this.state.edits.showRedeemButton !== undefined
          ? this.state.edits.showRedeemButton
          : this.state.album.showRedeemButton,
      showAttachmentButton:
        this.state.edits.showAttachmentButton !== undefined
          ? this.state.edits.showAttachmentButton
          : this.state.album.showAttachmentButton,
      redeemButtonText:
        this.state.edits.redeemButtonText || this.state.album.redeemButtonText || "Download Your Bundle",
      attachmentButtonText:
        this.state.edits.attachmentButtonText || this.state.album.attachmentButtonText || "Download Attachment",
    }

    // Upload new picture (if exists)
    try {
      if (this.state.edits.picture) {
        this.setLoading(true, `Uploading new album art`)
        await uploadAlbumArt(this.state.id, this.state.album, this.state.edits.picture)
      }
    } catch (err) {
      console.error(err)
      this.setError(err)
    }

    // Upload new tracks (if exists)
    try {
      if (this.state.edits.tracks) {
        this.setLoading(true, `Uploading tracks. This may take a few minutes.`)
        await uploadTracks(this.state.id, this.state.edits.tracks, this.state.album.tracks.length + 1)
      }
    } catch (err) {
      console.error(err)
      this.setError(err)
    }

    try {
      if (this.state.edits.trackOrder) {
        await firebase.firestore().collection("albums").doc(this.state.id).update({
          tracks: this.state.edits.trackOrder,
        })
      }
    } catch (err) {
      console.error(err)
      this.setError(err)
    }

    // Upload new files (if exists)
    try {
      if (this.state.edits.files) {
        this.setLoading(true, `Uploading files. This may take a few minutes`)
        await uploadFiles(this.state.id, this.state.edits.files)
      }
    } catch (err) {
      console.error(err)
      this.setError(err)
    }

    // Trigger rezip (if applicable)
    try {
      if (this.state.edits.picture || this.state.edits.tracks || this.state.edits.files) {
        await triggerRezip(this.state.id)
      }
    } catch (err) {
      console.error(err)
      this.setError(err)
    }

    // Update doc with new text data
    try {
      await docRef.update(data)
    } catch (err) {
      console.error(err)
      this.setError(err)
    }

    this.setLoading(false)
    window.location.reload()
  }

  render() {
    return (
      <AlbumContext.Provider value={this.state}>
        <div className="ViewAlbumPage">
          {this.state.isLoading && (
            <div className="overlay">
              <Loader color="white" description={this.state.loadingDescription} />
            </div>
          )}
          {!this.state.isLoading && (
            <>
              <section className="section">
                <div className="container">
                  <div className="columns">
                    <div className="column is-7">
                      {this.state.hasError && this.state.errorDescription && (
                        <div className="message is-danger">
                          <div className="message-header">Error</div>
                          <div className="message-body">{this.state.errorDescription}</div>
                        </div>
                      )}
                      <EditorPanel />
                    </div>
                    <div className="column is-5">
                      <SettingsPanel />
                      <div className="panel">
                        <div className="panel-heading">Files</div>
                        <div className="panel-block">
                          <div className="container">
                            <div className="my-3">
                              <FileDropzone />
                            </div>
                            {this.state.edits.files && (
                              <div className="to-be-added">
                                <p className="my-2">
                                  <strong>Files to be added</strong>
                                </p>
                                <div className="my-2">
                                  <FileDropzoneList />
                                </div>
                              </div>
                            )}
                            <div className="label">Files (Sorted alphabetically)</div>
                            <div className="files">
                              <FileList files={this.state.album.files} featured={this.state.album.featured} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="section">
                <div className="container">
                  <div className="panel">
                    <div className="panel-heading">
                      <div className="level">
                        <p>Tracks</p>
                        <button
                          className="button is-link mr-2"
                          onClick={() => this.setState({ ...this.state, hideDisabled: !this.state.hideDisabled })}>
                          {this.state.hideDisabled ? "Show Disabled" : "Hide Disabled"}
                        </button>
                        <SaveButton />
                      </div>
                    </div>
                    <div className="panel-block">
                      <div className="container">
                        <div className="field">
                          <div className="my-3">
                            <TrackDropzone />
                          </div>
                          {this.state.edits.tracks && (
                            <div className="to-be-added">
                              <p className="my-2">
                                <strong>Tracks to be added</strong>
                              </p>
                              <TrackDropzoneList />
                            </div>
                          )}
                        </div>
                        <div className="label">Tracks (Drag to Sort)</div>
                        <div className="tracks">
                          <TrackList tracks={this.state.album.tracks} hideDisabled={this.state.hideDisabled} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="section">
                <div className="container">
                  <div className="panel">
                    <div className="panel-heading">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <p>Custom Codes</p>
                          </div>
                        </div>
                        <div className="level-right">
                          <div className="pr-2">
                            <GenerateCodeFormButtom />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-block">
                      <div className="container">
                        <CodeList albumId={this.state.id} codes={Object.values(this.state.codes)} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section">
                <div className="container">
                  <div className="panel">
                    <div className="panel-heading">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <p>Code Groups</p>
                          </div>
                        </div>
                        <div className="level-right">
                          <div className="pr-2">
                            <GenerateCodeFormButtom />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-block">
                      <div className="container">
                        <GroupList
                          registerListener={this.registerListeners.bind(this)}
                          deregisterListener={this.deregisterListeners.bind(this)}
                          albumId={this.state.id}
                          groups={Object.values(this.state.groups).sort((a, b) =>
                            a.data().createdAt - b.data().createdAt ? 1 : -1
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </AlbumContext.Provider>
    )
  }
}

export default withRouter(ViewAlbumPage)
