import React from "react"

const CodeViewerPopup = function ({ doc }) {
  const { title, albumId, createdAt, description, downloadsUsed, downloadsAllowed, datesUsed } = doc.data()
  return (
    <div className="CodeViewerPopup">
      <div className="content">
        <h1 className="subtitle">{title}</h1>
        <h2 className="description">{description}</h2>
        <div className="stats">
          <ul>
            <li>Downloads Allowed: {downloadsAllowed}</li>
            <li>Downloads Used: {downloadsUsed}</li>
            <li>Dates Used: {JSON.stringify(datesUsed)}</li>
            <li>Created At: {createdAt.toDate().toLocaleString()}</li>
          </ul>
        </div>
        <a href={`/album/${albumId}`}>Go to Album</a>
      </div>
    </div>
  )
}

export default CodeViewerPopup
