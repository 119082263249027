import React from "react"
import ReactTooltip from "react-tooltip"

import Swal from "sweetalert2"
import firebase from "firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

import { generateCode, handleCodeUpdateSwalValidate, persistCode } from "../helpers"
import withReactContent from "sweetalert2-react-content"
import CodeGeneratorForm from "../components/codeGeneratorForm"

import "./codeList.scss"

const ReactSwal = withReactContent(Swal)

class CodeList extends React.Component {
  constructor(props) {
    super(props)
    this.albumId = props.albumId
    this.state = {
      id: generateCode(12),
      codes: props.codes,
      sort: "title",
      ordering: "desc",
    }
    this.containerRef = React.createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.codes !== this.state.codes) {
      this.setState({ ...this.state, codes: nextProps.codes })
    }
    return true
  }

  deleteCode = async (id) => {
    await firebase.firestore().collection("codes").doc(id).delete()
  }

  onUpdateClicked = (currentDoc) => {
    ReactSwal.fire({
      html: <CodeGeneratorForm doc={currentDoc} />,
      showCancelButton: true,
      confirmButtonText: "Update",
      preConfirm: async () => {
        try {
          const newData = handleCodeUpdateSwalValidate()
          ReactSwal.showLoading()
          ReactSwal.disableButtons()
          await this.update(currentDoc, newData)
          ReactSwal.hideLoading()
        } catch (err) {
          Swal.showValidationMessage(err)
        }
      },
    })
  }

  update = async (currentDoc, newData) => {
    const ref = firebase.firestore().collection("codes").doc(currentDoc.id)
    if (currentDoc.id !== newData.code) {
      await ref.delete()
      await persistCode({ albumId: this.albumId, ...newData, downloadsUsed: currentDoc.data().downloadsUsed })
    } else {
      await ref.update({ ...newData })
    }
  }

  renderResults = () => {
    const results = []
    let docs = Array.isArray(this.state.codes) ? this.state.codes : Object.values(this.state.codes)

    if (!docs.length) {
      return (
        <article className="message is-danger">
          <div className="message-header">No Codes found.</div>
        </article>
      )
    }

    docs.sort((a, b) => {
      const propA = a.data()[this.state.sort]
      const propB = b.data()[this.state.sort]

      if (this.state.ordering === "asc") {
        return propA < propB ? 1 : -1
      } else {
        return propA > propB ? 1 : -1
      }
    })

    for (const doc of docs) {
      const { downloadsAllowed, downloadsUsed } = doc.data()

      results.push(
        <tr key={doc.id}>
          <td className="hover-pointer" onClick={this.onUpdateClicked.bind(null, doc)}>
            {doc.id}
          </td>
          <td>{Number(downloadsAllowed).toLocaleString()}</td>
          <td>{Number(downloadsUsed).toLocaleString()}</td>
          <td>{Number(downloadsAllowed - downloadsUsed).toLocaleString()}</td>
          <td>
            <div className="level">
              <FontAwesomeIcon
                data-tip="Delete Code"
                icon={faTrash}
                className="has-text-danger icon"
                onClick={() =>
                  Swal.fire({
                    icon: "warning",
                    title: "Are you sure?",
                    text: `This will remove access to code: '${doc.id}'`,
                    confirmButtonText: "Delete",
                    showCancelButton: true,
                    focusCancel: true,
                    preConfirm: async () => {
                      try {
                        Swal.showLoading()
                        await this.deleteCode(doc.id)
                      } catch (err) {
                        Swal.showValidationMessage(err)
                      }
                    },
                  })
                }
              />
            </div>
          </td>
        </tr>
      )
    }

    return (
      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Code</th>
            <th>Allowed</th>
            <th>Used</th>
            <th>Remaining</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{results}</tbody>
      </table>
    )
  }

  render() {
    return (
      <div className="CodeList" ref={this.containerRef}>
        <div className="sorting">
          <div className="control">
            Property:{" "}
            <label className="radio">
              <input
                type="radio"
                name={`sort-${this.state.id}`}
                value="title"
                checked={this.state.sort === "title"}
                onChange={() => this.setState({ ...this.state, sort: "title" })}
              />{" "}
              Title
            </label>
            <label className="radio">
              <input
                type="radio"
                name={`sort-${this.state.id}`}
                value="createdAt"
                checked={this.state.sort === "createdAt"}
                onChange={() => this.setState({ ...this.state, sort: "createdAt" })}
              />{" "}
              CreatedAt
            </label>
            <label className="radio">
              <input
                type="radio"
                name={`ordering-${this.state.id}`}
                value="asc"
                checked={this.state.ordering === "asc"}
                onChange={() => this.setState({ ...this.state, ordering: "asc" })}
              />{" "}
              Ascending
            </label>
            <label className="radio">
              <input
                type="radio"
                name={`ordering-${this.state.id}`}
                value="desc"
                checked={this.state.ordering === "desc"}
                onChange={() => this.setState({ ...this.state, ordering: "desc" })}
              />{" "}
              Descending
            </label>
          </div>
        </div>
        {this.renderResults()}
        <ReactTooltip />
      </div>
    )
  }
}

export default CodeList
