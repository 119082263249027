import React from "react"
import { Link } from "react-router-dom"

import firebase from "firebase/app"
import "firebase/auth"

import { useAuth } from "../hooks/useAuth"

import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const ReactSwal = withReactContent(Swal)

const Navbar = (props) => {
  const { isSignedIn, user } = useAuth()

  const handleLogout = () => firebase.auth().signOut()
  const handleEmbedPopup = () => {
    const code1 = `<div class="vigil-project-embed" data-album-id="ALBUM_ID"></div>
<script src="https://admin.vigilproject.app/helper.js"></script>`
    const code2 = `<div class="vigil-project-embed" data-album-id="ALBUM_ID" data-is-custom="true"></div>
<script src="https://admin.vigilproject.app/helper.js"></script>`
    ReactSwal.fire({
      title: "Embed Code",
      html: (
        <div>
          <p>
            <strong>For Generated / 8 Character Codes</strong>
          </p>
          <pre>{code1}</pre>

          <div className="py-2"></div>
          <p>
            <strong>For Custom Codes</strong>
          </p>
          <pre>{code2}</pre>
        </div>
      ),
    })
  }

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item subtitle" href="/">
            Vigil Project Linker
          </a>
        </div>
        <div className="navbar-search"></div>
        <div className="navbar-menu">
          <div className="navbar-end">
            {!isSignedIn && (
              <Link className="navbar-item" to="/login">
                Login
              </Link>
            )}
            {isSignedIn && (
              <>
                <p className="navbar-item">{user.email}</p>
                <Link className="navbar-item" to="/import">
                  Import
                </Link>
                <button className="navbar-item is-link-like" onClick={handleEmbedPopup}>
                  Embed Code
                </button>
                <button className="navbar-item is-link-like" onClick={handleLogout}>
                  Logout
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
