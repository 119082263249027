import React from "react"
import "./loader.scss"

const Loader = ({ description, color, size }) => {
  return (
    <div className={`Loader ${color ? `is-${color}` : ""} ${size ? `is-${size}` : ""}`}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {description && <p className="description">{description}</p>}
    </div>
  )
}

export default Loader
