import React from "react"

import firebase from "firebase/app"
import "firebase/firestore"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import CodeViewerPopup from "./codeViewerPopup"

import CodeGeneratorForm from "./codeGeneratorForm"
import { handleCodeUpdateSwalValidate, persistCode } from "../helpers"

const ReactSwal = withReactContent(Swal)

const SearchBar = (props) => {
  const inputRef = React.createRef()
  let currentDoc = null

  const update = async (currentDoc, newData) => {
    const ref = firebase.firestore().collection("codes").doc(currentDoc.id)
    if (newData.code && currentDoc.id !== newData.code) {
      await ref.delete()
      await persistCode({
        albumId: currentDoc.data().albumId,
        ...newData,
        downloadsUsed: currentDoc.data().downloadsUsed,
      })
    } else {
      await ref.update({ ...newData })
    }
  }

  const showEditPopup = async (e) => {
    ReactSwal.fire({
      html: <CodeGeneratorForm doc={currentDoc} />,
      showCancelButton: true,
      confirmButtonText: "Update",
      focusCancel: true,
      preConfirm: async () => {
        try {
          const newData = handleCodeUpdateSwalValidate()
          ReactSwal.showLoading()
          ReactSwal.disableButtons()
          await update(currentDoc, newData)
          ReactSwal.hideLoading()
        } catch (err) {
          ReactSwal.showValidationMessage(err)
        }
      },
    })
  }

  const search = async () => {
    const value = inputRef.current.value.toUpperCase()
    inputRef.current.disabled = true
    const doc = await firebase.firestore().collection("codes").doc(value).get()
    console.log(doc.data())

    if (doc.exists) {
      currentDoc = doc
      ReactSwal.fire({
        title: `Code: ${value}`,
        focusCancel: true,
        html: <CodeViewerPopup doc={currentDoc} />,
        cancelButtonText: "Close",
        showCancelButton: true,
        confirmButtonText: "Edit",
        preConfirm: showEditPopup,
      })
    } else {
      Swal.fire({
        title: `Code not found`,
        icon: "warning",
        toast: true,
        position: "top-end",
      })
    }
    inputRef.current.disabled = false
  }

  return (
    <div className="field search has-addons">
      <div className="control has-icons-left is-expanded">
        <input
          ref={inputRef}
          type="text"
          className="input"
          style={{ textTransform: "uppercase" }}
          placeholder={"Code..."}
          maxLength={8}
        />
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </div>
      <div className="control">
        <button className="is-primary button" onClick={search}>
          Search
        </button>
      </div>
    </div>
  )
}

export default SearchBar
